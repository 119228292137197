<template>
  <LoadingZone :loading="loading" :dom-visibility="false">
    <div class="orderCard">
      <template v-if="hasOrder">
        <button
          class="btn btn-light btn-block b-radius-0 text-uppercase liveOrders__view--closeModal d-none"
          @click="$modal.hide('order')"
        >
          <TimesSVG class="w-8 fill_dark mr-2" />
          {{ $t("label.close_order") }}
        </button>
        <router-link
          tag="button"
          :to="{
            name: 'orders.live',
            params: {
              businessId,
            },
          }"
          class="btn btn-light btn-block b-radius-0 text-uppercase liveOrders__view--backOrders d-none m-0"
        >
          <ArrowAltLeftSVG class="w-10 fill_dark mr-1" />
          {{ $t("label.orders") }}
        </router-link>
        <Header :order="orderDetails" />
        <div class="orderCard__body">
          <div class="orderCard__btnTop">
            <Help :order-uid="order.uid" />
            <Receipt :order-id="order.id" />
          </div>
          <Details
            :order="orderDetails"
            :outlet-name="outlet.name"
            :business-id="business.id"
            :business-name="business.name"
            :business-category="business.category"
            :spot="spot"
            :customer="customer"
            :delivery="delivery"
            :delivery-services="deliveryServices"
          />
          <div v-if="lines.length" class="pt-3 pb-4">
            <LineItem
              v-for="(line, index) in lines"
              :key="`${index}-${line.id}`"
              :line="line"
              :business-id="business.id"
              class="mb-3"
            />
          </div>
          <div v-if="items.length" class="pt-3 pb-4">
            <Item
              v-for="(item, index) in items"
              :key="`${index}-${item.id}`"
              :show-discount-tag="
                hasDiscountedItems && discountedItems.includes(item.id)
              "
              :item="item"
              :business-id="business.id"
              class="mb-3"
            />
          </div>
          <Charges
            :business-id="business.id"
            :order="orderDetails"
            :tip="tip"
            :tax-rate="outlet.taxRate"
          />
        </div>
        <Actions
          v-if="forceEnableActions || (isOrderLive && !hasDeliverect)"
          :order="orderDetails"
          :preparation-minutes="serviceSettings.preparationMinutes"
          :estimated-completion-minutes="
            serviceSettings.estimatedCompletionMinutes
          "
          :outlets="outlets"
          :delivery-services="deliveryServices"
          :service-settings="serviceSettings"
          :printer-settings="outlet.printerSettings"
        />
        <template v-else>
          <div v-if="isOrderLive" class="deliverect-lock-actions">
            <div>
              <img
                class="deliverect-lock-actions__message-logo"
                src="@/assets/img/deliverect-logo.png"
                alt="Deliverect Logo"
              />
              <!-- eslint-disable -->
            <span
              class="deliverect-lock-actions__message-text"
              v-html="$t('message.delirect_info_live_order.text')"
            ></span>
             <!-- eslint-enable -->
            </div>
            <div>
              <button
                class="deliverect-lock-actions__button"
                @click.prevent="forceEnableActions = true"
              >
                {{ $t("message.delirect_info_live_order.button") }}
              </button>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <p class="h4 text-center my-5 mx-4">
          {{ $t("message.order_fetch_failure") }}
        </p>
      </template>
    </div>
  </LoadingZone>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import Header from "./components/Header.vue";
import Details from "./components/Details.vue";
import LineItem from "./components/Line.vue";
import Item from "./components/Item.vue";
import Actions from "./components/Actions.vue";
import Charges from "./components/Charges.vue";
import Receipt from "./components/Receipt.vue";
import Help from "./components/Help.vue";
import ForbiddenError from "@/api/errors/ForbiddenError";
import store from "./store";
import { ORDER_OPENED } from "@/plugins/analytics/events";
import { liveStatuses } from "@/utils/enums/order";
import { mapGetters, mapActions } from "vuex";
import { find, propEq, propOr, omit, includes, __ } from "ramda";
import { isNilOrEmpty } from "ramda-extension";
import TimesSVG from "@/assets/svg/icons/times.svg";
import ArrowAltLeftSVG from "@/assets/svg/icons/arrow-alt-left.svg";
import { useHelpers } from "@/v2/composable/use-helpers";
import { listenFromTenant, unlistenFromTenant } from "@/v2/core/broadcasting";
import { report } from "@chatfood/bug-reporter";
import { Toast } from "@/design-system";

export default {
  name: "Order",
  components: {
    LoadingZone,
    Header,
    Details,
    LineItem,
    Item,
    Actions,
    Charges,
    Receipt,
    Help,
    TimesSVG,
    ArrowAltLeftSVG,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    forceEnableActions: false,
    events: [
      "order.new",
      "order.rejected",
      "order.accepted",
      "order.marked-as-ready",
      "order.complete",
      "order.cancelled",
      "order.closed",
      "delivery.created",
      "delivery.failed",
      "delivery.pending",
    ],
  }),
  computed: {
    ...mapGetters({
      order: "orderCard/getData",
      outlets: "outlets/getData",
      businesses: "businesses/getData",
      userId: "auth/getId",
    }),
    hasDeliverect() {
      return useHelpers().outletHasDeliverect(this.order.outlet_id);
    },
    hasOrder() {
      return !isNilOrEmpty(this.order);
    },
    hasDiscountedItems() {
      return Boolean(
        this.order.discounts.length &&
          this.order.discounts[0].discounted_items.length
      );
    },
    discountedItems() {
      return (
        this.order.discounts.length && this.order.discounts[0].discounted_items
      );
    },
    isOrderLive() {
      return includes(this.order.status, liveStatuses.values());
    },
    orderDetails() {
      return omit(["lines", "customer", "delivery"], this.order);
    },
    customer() {
      return propOr({}, "customer", this.order);
    },
    spot() {
      return propOr(null, "table_name", this.order);
    },
    delivery() {
      return propOr(null, "delivery", this.order);
    },
    lines() {
      return propOr([], "lines", this.order);
    },
    tip() {
      return propOr(0, "tip_amount", this.order);
    },
    items() {
      return propOr([], "items", this.order);
    },
    outlet() {
      return find(propEq("id", this.order.outlet_id), this.outlets) || {};
    },
    business() {
      return find(propEq("id", this.order.business_id), this.businesses) || {};
    },
    currency() {
      return propOr({}, "currency", this.business);
    },
    deliveryServices() {
      return propOr([], "deliveryServices", this.outlet);
    },
    orderingService() {
      return find(
        propEq("slug", this.order.ordering_service),
        this.outlet.orderingServices
      );
    },
    serviceSettings() {
      const foundSettings = find(
        propEq("operationStatus", this.orderingService?.operationStatus),
        propOr([], "settings", this.orderingService)
      );

      const pick = propOr(__, __, foundSettings);

      return {
        preparationMinutes: pick(0, "preparationMinutes"),
        estimatedCompletionMinutes: pick(0, "estimatedCompletionMinutes"),
        operationStatus: pick(null, "operationStatus"),
      };
    },
  },
  created() {
    this.$store.registerModuleOnce("orderCard", store);
  },
  async mounted() {
    this.listenToUpdates();
    this.watchOrder();
    await this.fetchOrder();
    this.$analytics.trackEvent(ORDER_OPENED, { id: this.orderId });
  },
  beforeDestroy() {
    this.unlistenToUpdates();
    this.clearStore();
  },
  methods: {
    ...mapActions({
      fetchStore: "orderCard/fetch",
      clearStore: "orderCard/reset",
    }),
    watchOrder() {
      this.$store.watch(
        (_, getters) => getters["orderCard/getData"],
        (val) => this.$emit("changed", val),
        { deep: true }
      );
    },
    async fetchOrder(showLoadState = true) {
      try {
        this.loading = showLoadState;
        await this.fetchStore(this.orderId);
      } catch (error) {
        if (error instanceof ForbiddenError) {
          return this.orderForbidden();
        }

        report(error);

        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
    orderForbidden() {
      this.$emit("forbidden");

      new Toast().create({
        type: "warning",
        text: this.$t("message.order_forbidden"),
      });
    },
    listenToUpdates() {
      listenFromTenant(this.userId, this.events, this.orderEventsHandler);
    },
    unlistenToUpdates() {
      unlistenFromTenant(this.userId, this.events, this.orderEventsHandler);
    },
    orderEventsHandler(order) {
      if (this.order.id !== order.id) return;
      this.fetchOrder(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.deliverect-lock-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 24px;
  background-color: #f4f6f9;
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: column;
  }

  &__message-logo {
    height: 28px;
    width: 28px;
    margin-right: 14px;
  }

  &__message-text {
    font-size: 14px;
    line-height: 150%;
    color: #071c4d;
  }

  &__button {
    background-color: #ebeff4;
    border: none;
    color: #1258ff;
    border-radius: 7px;
    padding: 7px 55px;

    @media (max-width: 550px) {
      margin-top: 7px;
    }
  }
}
</style>
